@import "../../index.scss";

.container {
	height: 130px;
	border-bottom: 1px solid #dde1ed;
	& .title {
		margin: 24px 0px;
		font-size: 14px;
		font-weight: 400;
		text-align: right;
		span {
			font-weight: 700;
		}
	}
}

.btns {
	display: flex;
	justify-content: flex-end;
	& button {
		margin-left: 12px;
	}
}

@media (max-width: 500px) {
	.btns {
		display: flex;
		justify-content: flex-end;
		& button {
			margin-left: 3px;
			margin-right: 3px;
		}
	}
	.container {
		z-index: 100;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 12px 24px;
		height: 70px;
		background-color: #fff;
		box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
		& .title {
			display: none;
		}
	}
}
