@import "../../index.scss";

.title {
  font-weight: 700;
  font-size: 28px;
  text-align: left;
}

.subtitle {
  margin: 24px 0;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
