@import "../../index.scss";

.dropdown {
	color: #2f3644;
	position: relative;
	user-select: none;

	.noneShow {
		visibility: hidden;
		opacity: 0;
		// transition: visibility 0s, opacity 1s linear;
	}
	.title {
		font-size: 13px;
		font-weight: 400;
		text-align: left;
		margin-bottom: 6px;
	}

	.dropdownBtn {
		font-weight: 600;
		height: 60px;
		padding: 18px;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 5px;
		border: 1px solid #dde1ed;
		cursor: pointer;
		&:hover {
			border: 1px solid #028dd8;
		}
		&:hover svg {
			fill: rgba(2, 141, 216, 1);
		}
	}
	.dropdownContent {
		position: absolute;
		top: 100%;
		left: 0;
		background-color: #fff;
		box-shadow: 0px 0px 25px rgba(47, 54, 68, 0.25);
		border-radius: 5px;
		width: 100%;
		z-index: 1;
		padding: 10px 0;

		.dropdownItem {
			display: flex;
			align-items: center;
			font-weight: 600;
			font-size: 14px;
			padding: 8px 0;
			padding-left: 18px;
			cursor: pointer;
			border-radius: 5px;
			position: relative;

			&:hover {
				background-color: #fff;
				color: #028dd8;
			}

			input[type="radio"] {
				display: none;
			}
			span {
				display: inline-block;
				cursor: pointer;
				position: relative;
				padding-left: 25px;
				margin-right: 0;
				line-height: 18px;
				user-select: none;
				width: 100%;
			}
			span:before {
				border-radius: 50%;
				border: 1.5px solid rgba(23, 119, 172, 0.548);
				content: "";
				display: inline-block;
				width: 18px;
				height: 18px;
				position: absolute;
				left: 0;
				bottom: 1px;
				background: #fff 0 0 no-repeat;
			}
			input[type="radio"]:checked + span:before {
				background: radial-gradient(
					circle,
					rgba(2, 141, 216, 1) 50%,
					rgba(255, 255, 255, 1) 50%
				);
			}
		}
		.active {
			&::after {
				content: none;
			}
		}
	}
	.show {
		visibility: visible;
		opacity: 1;
	}
}

@media (max-width: 500px) {
	.title {
		display: none;
	}
	.dropdown {
		width: 312px !important;
	}
}
