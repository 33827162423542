.container {
	margin: 0 auto;
	margin-top: 35px;
	max-width: 960px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.mobileTitle {
	display: none;
	font-size: 16px;
	font-weight: 600;
}

.dropdownBtn {
	font-weight: 600;
	height: 60px;
	padding: 18px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px;
	border: 1px solid #dde1ed;
	cursor: pointer;
	&:hover {
		border: 1px solid #028dd8;
	}
	&:hover svg {
		fill: rgba(2, 141, 216, 1);
	}
}
@media (max-width: 500px) {
	.container {
		gap: 12px;
		display: grid;
		padding: 0 24px;
		justify-content: center;
		& button {
			width: 312px !important;
		}
	}
	.mobileTitle {
		display: block;
	}
}
