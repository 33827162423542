.container {
  margin-top: 24px;
  border-bottom: 1px solid #dde1ed;
  & h2 {
    font-size: 18px;
    font-weight: 600;
  }
  & p {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin: 24px 0px;
  }
}

.content {
  margin: 24px 0px;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  .item {
    cursor: pointer;
  }
}

@media (max-width: 500px) {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .item {
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
  }
}
