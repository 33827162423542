@import "../../styles/variable.scss";

.btn1 {
	height: 300px;
}
.btn {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 60px;
	border-radius: 5px;
	font-weight: 600;
	font-size: 13px;
	cursor: pointer;
	user-select: none;

	p {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: 600;
		font-size: 14px;

		.svg {
			width: 20px;
			height: 20px;
			margin-right: 12px;
		}
	}
}
.primary {
	width: 234px;
	background-color: $blue;
	font-size: 15px;
	color: $white;
	transition: all 0.2s;
	&:hover {
		background: #0277bd;
	}
}
.social {
	background: #e6f6ff;
	height: 40px;

	width: 211px;
	font-size: 15px;
	color: #03a5fc;
	transition: all 0.2s linear;
	&:hover {
		background: #028dd8;
		color: #fff;
	}
}

.isCopy {
	background-color: #89ac76;
	color: white;
	pointer-events: none;
}

.whup {
	width: 149px;
	background-color: $white;
	border: 1px solid $green;
	color: $green;
	transition: all 0.2s;
	&:hover {
		color: $white;
		background-color: $green;
	}
}

.tg {
	width: 149px;
	background-color: $white;
	border: 1px solid $blue;
	color: $blue;
	transition: all 0.2s;

	align-items: center;
	&:hover {
		color: $white;
		background-color: $blue;
	}
}

@media (max-width: 500px) {
	.btn {
		& span {
			display: none;
		}
	}
	.primary {
		flex-grow: 1;
		height: 44px;
	}
	.tg {
		width: 44px;
		height: 44px;
		& .svg {
			margin-left: 11px;
		}
	}
	.whup {
		width: 44px;
		height: 44px;
		& .svg {
			margin-left: 12px;
		}
	}
	.social {
		height: 40px;
		width: 158px;
	}
	.isCopy {
		background-color: #89ac76 !important;
		color: white;
		pointer-events: none;
	}
}
