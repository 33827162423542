@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

$white: #fff;
$blue: #028DD8;
$green: #829A3A; //for whatsUp
$black: #2F3644;
$bg-color: #F2F4F7;

$font-family:'Open Sans', sans-serif;
$fs-large: 28px;
$fs-big: 18px;
$fs-normal: 14px;
